
import { AnalyticsEvent, WidgetType } from "@evercam/shared/types"

export default {
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      settings: {
        fullscreen: false,
        download: false,
        zoom: false,
        playpause: false,
        refresh: 5,
      },
      refreshRateItems: [
        { text: "5 seconds", value: 5 },
        { text: "10 seconds", value: 10 },
        { text: "30 seconds", value: 30 },
        { text: "1 Minute", value: 60 },
      ],
    }
  },
  watch: {
    settings: {
      deep: true,
      handler(value) {
        this.$emit("settings-changed", value)
      },
    },
  },
  mounted() {
    for (const key of Object.keys(this.widget.settings)) {
      if (this.widget.settings[key] !== undefined) {
        this.settings[key] = this.widget.settings[key]
      }
    }
  },
  methods: {
    onDownloadChange() {
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsSettingToggleDownload, {
        widget: WidgetType.LiveWidget,
        hasDownload: this.settings.download,
      })
    },
    onFullScreenChange() {
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsSettingToggleFullScreen, {
        widget: WidgetType.LiveWidget,
        hasFullScreen: this.settings.fullscreen,
      })
    },
    onPlayPauseChange() {
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsSettingTogglePlayPause, {
        widget: WidgetType.LiveWidget,
        hasPlayPause: this.settings.playpause,
      })
    },
    onRefreshChange() {
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsSettingRefreshRate, {
        widget: WidgetType.LiveWidget,
        refreshEveryInMs: this.settings.refresh,
      })
    },
    onZoomChange() {
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsSettingToggleZoom, {
        widget: WidgetType.LiveWidget,
        hasZoom: this.settings.zoom,
      })
    },
  },
}
