
import { AnalyticsEvent, WidgetType } from "@evercam/shared/types"

export default {
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      delayUnitItems: [
        { text: this.$t("content.minutes"), value: "minute" },
        { text: this.$t("content.hours"), value: "hour" },
        { text: this.$t("content.days"), value: "day" },
      ],
      settings: {
        hasDelay: false,
        delay: 5,
        delayUnit: "minute",
        darkMode: false,
        calendar: false,
      },
      editValueDebounce: null,
      editValueDebounceTimeOut: 400,
    }
  },
  watch: {
    settings: {
      deep: true,
      handler(value) {
        this.$emit("settings-changed", value)
      },
    },
  },
  mounted() {
    for (const key of Object.keys(this.widget.settings)) {
      if (this.widget.settings[key] !== undefined) {
        this.settings[key] = this.widget.settings[key]
      }
    }
  },
  methods: {
    onDarkModeChange() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingToggleRecordingsDarkMode,
        {
          widget: WidgetType.Recording,
          hasDarkMode: this.settings.darkMode,
        }
      )
    },
    onDelayChange() {
      clearTimeout(this.editValueDebounce)
      this.editValueDebounce = setTimeout(() => {
        this.saveDelayAnalyticsEvent()
      }, this.editValueDebounceTimeOut)
    },
    onDelayUnitChange() {
      this.saveDelayAnalyticsEvent()
    },
    onHasDelayChange() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingToggleRestrictRecentImages,
        {
          widget: WidgetType.Recording,
          hasDelay: this.settings.hasDelay,
        }
      )
    },
    onSidebarChange() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingToggleRecordingsCalendar,
        {
          widget: WidgetType.Recording,
          hasSideBar: this.settings.sidebar,
        }
      )
    },
    saveDelayAnalyticsEvent() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingRestrictRecentImagesInput,
        {
          widget: WidgetType.Recording,
          delay: this.settings.delay,
          delayUnit: this.settings.delayUnit,
        }
      )
    },
  },
}
