
import {
  AnalyticsEvent,
  RessourceByType,
  WidgetType,
} from "@evercam/shared/types"
import { decamelize } from "humps"
import { updateQuery } from "@evercam/shared/utils"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useCameraStore } from "@/stores/camera"
import { mapStores } from "pinia"

import CameraRessource from "@/components/widgets/ressources/CameraRessource"
import ProjectRessource from "@/components/widgets/ressources/ProjectRessource"
import WidgetForm from "@/components/widgets/WidgetForm"
import WidgetCredentials from "@/components/widgets/WidgetCredentials"

export default {
  name: "Widgets",
  components: {
    CameraRessource,
    ProjectRessource,
    WidgetForm,
    WidgetCredentials,
  },
  data() {
    return {
      options: {},
      widgets: [],
      total: 0,
      loading: false,
      sortBy: "inserted_at",
      sortDesc: true,
      optionsWatcherEnabled: false,
      isChangesLoading: false,
      ressourceComponents: {
        camera: "CameraRessource",
        project: "ProjectRessource",
      },
      headers: [
        {
          value: "widgetType",
          text: this.$t("content.widgets.headers.widgetType"),
          class: "subtitle-1 table-header",
          align: "center",
          visible: true,
          width: 100,
          sortable: false,
        },
        {
          value: "ressources",
          text: this.$t("content.widgets.headers.ressources"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 250,
        },
        {
          value: "allowedOrigin",
          text: this.$t("content.widgets.headers.origin"),
          class: "subtitle-1 table-header",
          align: "center",
          cellClass: "origin",
          visible: true,
          width: 100,
        },
        {
          value: "settings",
          text: this.$t("content.widgets.headers.settings"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 320,
        },
        {
          value: "active",
          text: "Status",
          class: "subtitle-1 table-header",
          align: "center",
          visible: true,
          width: 100,
        },
        {
          value: "actions",
          text: "Actions",
          class: "subtitle-1 table-header",
          align: "center",
          visible: true,
          width: 50,
        },
      ],
      editedWidget: null,
      shownCredentialsWidget: null,
    }
  },
  computed: {
    ...mapStores(useCameraStore),
  },
  watch: {
    options: {
      handler() {
        if (this.optionsWatcherEnabled) {
          this.getWidgets()
        }
        this.optionsWatcherEnabled = true
      },
      deep: true,
    },
  },
  mounted() {
    this.getWidgets()
  },
  methods: {
    async getWidgets(params = {}) {
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        sortBy = decamelize(sortBy[0])
        updateQuery(params)
        this.loading = true
        let response = await EvercamApi.widgets.index({
          ...params,
          sort: `${sortBy}|${this.whichSort(sortDesc[0])}`,
          limit: itemsPerPage,
          page: page,
        })

        this.widgets = response.items
        this.total = response.total
      } catch (error) {
        this.$notifications.error({
          text: `${this.$t("content.widgets.load_widgets_error")}`,
          error,
        })
      } finally {
        this.loading = false
      }
    },
    whichSort(type) {
      if (type) {
        return "desc"
      } else {
        return "asc"
      }
    },
    formatSettingsValue(value) {
      if (typeof value === "boolean" || typeof value === "object") {
        return ""
      } else {
        value
      }
    },
    async deleteWidget(widget) {
      if (
        await this.$confirmDialog.open({
          title: this.$t("content.widgets.delete_widget_title"),
          message: this.$t("content.widgets.delete_widget_message"),
        })
      ) {
        try {
          await EvercamApi.widgets.delete(widget.id)
          this.getWidgets()
        } catch (error) {
          this.$notifications.error({
            text: `${this.$t("content.widgets.delete_widgets_error")}`,
            error,
          })
        }
      }
    },
    async editWidget(item) {
      this.isChangesLoading = true
      try {
        this.editedWidget = await EvercamApi.widgets.show(item.id)
      } catch (error) {
        this.$notifications.error({
          text: `${this.$t("content.widgets.load_widgets_error")}`,
          error,
        })
      } finally {
        this.isChangesLoading = false
      }
    },
    createWidget() {
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsAddWidgetClick)
      this.editedWidget = {
        widgetType: WidgetType.LiveWidget,
        settings: {
          width: 100,
          resolution: "%",
          height: 100,
          heightResolution: "%",
        },
        ressources: { camera: this.cameraStore.cameras[0] },
      }
    },
    async showWidgetLink(item) {
      this.isChangesLoading = true
      try {
        this.shownCredentialsWidget = await EvercamApi.widgets.show(item.id)
      } catch (error) {
        this.$notifications.error({
          text: `${this.$t("content.widgets.load_widgets_error")}`,
          error,
        })
      } finally {
        this.isChangesLoading = false
      }
    },
    changeWidgetState({ id, active }) {
      this.save({ id, active: !active })
    },
    async save(w) {
      this.isChangesLoading = true
      let widget = structuredClone(w)
      try {
        if (widget.id) {
          this.formatRessources(widget)
          await EvercamApi.widgets.update(widget.id, widget)
        } else {
          const [provider, id] = this.getProvider(widget)
          this.shownCredentialsWidget = await provider(id, widget)
        }
        this.editedWidget = null
        await this.getWidgets()
      } catch (error) {
        this.$notifications.error({
          text: `${this.$t("content.widgets.load_widgets_error")}`,
          error,
        })
      } finally {
        this.isChangesLoading = false
      }
    },
    formatRessources(widget) {
      if (RessourceByType.project.includes(widget.widgetType)) {
        widget.ressources = { projectId: widget.ressources?.project?.id }
      } else if (RessourceByType.camera.includes(widget.widgetType)) {
        widget.ressources = { cameraId: widget.ressources?.camera?.exid }
      }
    },
    getProvider(widget) {
      if (RessourceByType.project.includes(widget.widgetType)) {
        return [
          EvercamApi.widgets.createProjectWidget,
          widget.ressources.project.exid,
        ]
      } else if (RessourceByType.camera.includes(widget.widgetType)) {
        return [
          EvercamApi.widgets.createCameraWidget,
          widget.ressources.camera.exid,
        ]
      } else {
        throw new Error("Wrong ressource type")
      }
    },
    decamaliseSetting(settingName) {
      return this.capitalize(
        decamelize(settingName, { separator: " " }).toLowerCase()
      )
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    },
  },
}
