
import { AnalyticsEvent, WidgetType } from "@evercam/shared/types"

export default {
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      settings: {
        timeline: false,
        darkMode: false,
      },
    }
  },
  watch: {
    settings: {
      deep: true,
      handler(value) {
        this.$emit("settings-changed", value)
      },
    },
  },
  mounted() {
    for (const key of Object.keys(this.widget.settings)) {
      if (this.widget.settings[key] !== undefined) {
        this.settings[key] = this.widget.settings[key]
      }
    }
  },
  methods: {
    onDarkModeChange() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingToggleBIMCompareDarkMode,
        {
          widget: WidgetType.BimCompare,
          hasDarkMode: this.settings.darkMode,
        }
      )
      this.$emit("input", this.widget)
    },
    onTimelineChange() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingToggleBIMCompareTimeLine,
        {
          widget: WidgetType.BimCompare,
          hasTimeline: this.settings.timeline,
        }
      )
      this.$emit("input", this.widget)
    },
  },
}
