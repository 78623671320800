
import { VideoWallItemType } from "@evercam/shared/types/videoWall"
import { useProjectStore } from "@/stores/project"
import { useVideoWallStore } from "@/stores/videoWall"
import { mapStores } from "pinia"
import { AnalyticsEvent, WidgetType } from "@evercam/shared/types"

import VideoWallConfigurationDialog from "@/components/videoWalls/VideoWallConfigurationDialog"
const seconds = 1000

export default {
  components: {
    VideoWallConfigurationDialog,
  },
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      settings: {
        preset: null,
        refreshRate: 50 * seconds,
      },
      showVideoWallConfiguration: false,
      refreshRateItems: [
        { text: "50 Seconds", value: 50 * seconds },
        { text: "5 Minutes", value: 60 * 5 * seconds },
        { text: "1 Hours", value: 60 * 60 * seconds },
        { text: "2 Hours", value: 2 * 60 * 60 * seconds },
      ],
    }
  },
  computed: {
    ...mapStores(useProjectStore, useVideoWallStore),
    items() {
      this.projectStore.selectProject(this.widget.ressources.project?.exid)

      return this.projectStore.selectedProjectCameras.map((c) => ({
        value: c,
        type: VideoWallItemType.LiveView,
      }))
    },
  },
  watch: {
    settings: {
      deep: true,
      handler(value) {
        this.$emit("settings-changed", value)
      },
    },
    "widget.ressources": {
      deep: true,
      handler() {
        this.settings.preset = null
      },
    },
  },
  mounted() {
    for (const key of Object.keys(this.widget.settings)) {
      if (this.widget.settings[key] !== undefined) {
        this.settings[key] = this.widget.settings[key]
      }
    }
  },
  methods: {
    closeVideoWallConfiguration() {
      this.showVideoWallConfiguration = false

      this.settings.preset = this.videoWallStore.preset.map((el) => ({
        ...el,
        camera: el.camera.exid,
      }))
    },
    onClickLayout() {
      this.showVideoWallConfiguration = true
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsLayout, {
        widget: WidgetType.VideoWall,
      })
    },
    onRefreshRateChange() {
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsSettingRefreshRate, {
        widget: WidgetType.VideoWall,
        refreshEveryInMs: this.settings.refreshRate,
      })
    },
  },
}
